<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" id="card_section">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="me-2 d-inline-block">
                <i class="uil uil-history display-6 text-primary"></i>
              </div>
              <div class="mb-2 mb-lg-0 d-inline-block">
                <h4 class="fw-medium font-size-20 text-dark mb-0">
                  {{ title }}
                </h4>
              </div>
              <div class="ms-auto">
                <button class="btn btn-outline-info fw-medium" @click="handlePageChange(currentPage)">
                  <i class="uil uil-redo font-size-16 me-1"></i> Refresh
                </button>
              </div>

            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12 col-lg-12 col-xl-4">
                <label class="form-label mb-1">Keyword</label>
                <input
                    type="text"
                    placeholder="Keyword"
                    class="form-control mb-3"
                    :disabled="disabled"
                    v-model.trim="searchKeyword"
                    @keyup.enter="search()" 
                  />
              </div>
              <div class="col-12 col-md-4 col-lg mb-3">
                <label class="form-label mb-1">Status</label>
                    <select
                      class="form-select pe-5 font-size-13"
                      v-model="selectedStatus"
                      @change="handlePageChange(1)"
                      :disabled="disabled"
                    >
                      <option value="all">All Status</option>
                      <option value="pending">Pending</option>
                      <option value="problem">Problem</option>
                      <option value="done">Done</option>
                    </select>
              </div>
              <div class="col-12 col-md col-xl-4 mb-0">
                <label class="form-label mb-1">Date Range</label>
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format"
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="loading"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  class="mb-3"
                ></date-picker>
                <!-- 
                  @change="DateChanged()" -->
              </div>

              <div class="col-12 col-md-auto col-xl-auto">
                <label class="form-label mb-1 d-md-block d-none">&nbsp;</label>
                <div class="row">
                  <div class="col-6 pe-1 col-auto d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="clear()"
                    >
                      <i class="uil-redo me-1"></i> Clear
                    </button>
                  </div>
                  <div class="col-6 ps-1 col-auto d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="search()"
                    >
                      <i class="uil-search me-1"></i> Search
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl" v-if="disableExport=='test'">
                <div class="float-end">
                  <div class="mb-3 ml-auto">
                    <label class="form-label mb-1 d-md-block d-none">&nbsp;</label>
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="table-responsive text-nowrap font-size-14 position-relative"
            >
              <table
                id="freeze"
                class="table mt-2 mb-0 align-middle min-width-760"
                :class="{ 'table-hover table-striped': returnData.length > 0 }"
              >
                <thead class="text-uppercase">
                  <tr>
                    <th>ID</th>
                    <th>Type</th>
                    <th>Account ID</th>
                    <th class="text-end">Amount</th>
                    <th>Ref. Code/UTR</th>
                    <th>Log Content</th>
                    <th>Created Date</th>
                    <th class="text-center">Status</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!returnData.length && !loading">
                    <td  :colspan="(account_type === 'admin') ? 8 : 8" height="300" class="text-center text-muted">
                      <i class="uil uil-history me-1"></i> No Data
                      Available
                    </td>
                  </tr>
                  <tr v-if="loading">
                    <td  :colspan="(account_type === 'admin') ? 8 : 8" class="text-center text-muted" height="400">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>

                  <tr v-for="(value, index) in returnData" :key="index">
                   
                    <td>
                     {{ value.id }}
                    </td>
                   
                    <td>
                     {{ value.type }}
                    </td>
                    <td>
                     {{ value.account_id }}
                    </td>
                    <td class="text-end">
                     <span v-if="value.amount">
                     {{ convertCurrencyFormat(value.amount || 0,true) }}</span>
                    </td>
                    <td>
                     {{ value.reference_code }}
                    </td>
                    <td class="text-muted">
                     {{ value.content }}
                    </td>
                    <td width="120">
                      {{ value.system_datetime }}
                    </td>
                    <td>
                     <span class="badge bg-soft-success rounded-pill font-size-13" v-if="value.callback_status =='done'">Done</span> 
                     <span class="badge bg-soft-info rounded-pill font-size-13" v-else-if="value.callback_status =='pending'">Pending</span>
                     <span class="badge bg-soft-danger rounded-pill font-size-13" v-else-if="value.callback_status =='problem'">Problem</span>
                    </td>
                    <td class="text-muted">
                      <div class="font-size-13 text-success" v-if="value.callback_status=='done'">成功补单 - {{ value.item_id }} </div>
                      <div class="font-size-13 text-info " v-if="value.callback_status=='pending'">未找到对应订单 </div>
                      <div class="font-size-13 text-danger" v-if="value.callback_status=='problem'">回调失败 - {{ value.item_id }} </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" v-if="returnData.length && !loading">
              <div class="col mt-3">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      hide-ellipsis
                      @change="handlePageChange"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalData"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie
      :path="'/animate/loading_purple.json'"
      :title="'Loading'"
      :info="'Please wait...'"
      :show="showLottie"
      ref="lottieFunc"
    />
    <Common ref="commonFunc" />
    <extraInfo ref="modalFunc3" :data="{ 'extraInfo': currentClickedInfo}"/>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import extraInfo from "@/views/pages/app/modal/extraInfo2";
/**
 * Page
 */
const PageTitle = "Bot Update Logs";

const CancelToken = axios.CancelToken;
let cancel;

const usersDefault = {
  username: "",
  name: "All Users",
};

export default {
  components: {
    Layout,
    PageHeader,
    Lottie,
    Common,
    DatePicker,
    extraInfo
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },
  data() {
    return {
      currentClickedInfo:{title: "", info: {}, type: ""},
      accessToken: "",
      accessEmail: "",
      accessPhone: "",
      accessUsername: "",
      account_type:"",
      showLottie: false,
      title: "",
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: "",
          active: true,
        },
      ],
      formData: {
        apiID: "YOUR_API_ID",
        apiHash: "YOUR_API_HASH",
      },
      submitted: false,
      loading: false,
      buttonLoading: [false, false, false],
      currentPage: 1,
      currencySymbol: "₹",
      selectedUsers: usersDefault,
      usersList: [usersDefault],
      categories:[],
      seletedMethod: "",
      selectedStatus: "all",
      searchKeyword: "",
      searchMerchantItemId: "",
      filterType: "created_date",
      searchAmount: "",
      perPage: 50,
      totalData: 0,
      returnData: [],
      returnPaidTotal: 0,
      returnPayoutProcessed: 0,
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled: false,
      shortcuts: [],
      currentMerchantInfo:{},
      currentResellerInfo:{},
      create_modify_permission:false,
      updateData:{}
    };
  },
  middleware: "authentication",
  async mounted() {
    this.title = PageTitle;
    this.items[1].text = PageTitle;
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    await this.reload();
    this.accessToken = this.$refs.commonFunc.getToken();
    this.accessUsername = this.$refs.commonFunc.getUsername();
    this.date_range = this.$refs.commonFunc.last1Days();
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut();
    //this.getCountries();
    if (this.account_type=='admin') {
      this.getUserList();
    }
    await this.handlePageChange(1);
  },
  created() {},
  methods: {
    clickExtraInfo(title, object, type){
      this.currentClickedInfo = {title: "", info: {}, type: ""},
      this.currentClickedInfo = {title: title, info: {logs:object}, type: type}
      setTimeout(() => this.$refs.modalFunc3.showExtraInfoModal(), 0)
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value);
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc
          .convertCurrencyFormat(value)
          .replaceAll(".00", "");
      }
    },
    inputNumberOnly(event, parentModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, "");
      this[parentModel] = numericValue;
    },
    countryChanged() {
      //this.selectedBanks=BankDefault
      this.handlePageChange(1);
    },
    returnBankList() {
      if (this.selectedCountry.id !== -1) {
        return this.banksList.filter(
          (bank) => bank.countryId === this.selectedCountry.id
        );
      } else {
        return this.banksList;
      }
    },
   capitalize(str) {
      if (str.length === 0) return str; // return empty string if input is empty
      str = str.toLowerCase(); // convert all characters to lowercase
      return str
    },
    getUserList() {
      console.log("getUserList");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllUserList',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.selectedUsers = usersDefault;
            this.usersList = [this.selectedUsers];
            resData.data.forEach((element) => {
              this.usersList.push(element);
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    notBeforeToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    DateChanged() {
      console.log("datechange" + this.date_range);
      this.handlePageChange(1);
      this.currentPage = 1;
      this.disable_date = true;
    },
    handlePageChange(current_page) {
      this.pageNum = current_page;
      this.currentPage = current_page;
      this.getData(this.pageNum, this.search_value);
      //this.exportData(1)
    },

    search() {
      this.handlePageChange(1);
      this.currentPage = 1;
    },
    clear() {
      this.seletedMethod = "";
      this.selectedStatus = "all";
      this.searchKeyword = "";
      this.searchMerchantItemId = "";
      this.filterType = "created_date";
      this.searchAmount = "";
      this.date_range = this.$refs.commonFunc.last30Days();
      this.search_value = "";
      this.handlePageChange(1);
      this.currentPage = 1;
    },

    exportToExcel(Data, name, type) {
      var title = name.replace(/ /g, "_");
      var ws = window.XLSX.utils.json_to_sheet(Data);
      let objectMaxLength = [];
      for (let i = 0; i < Data.length; i++) {
        let value = Object.values(Data[i]);
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == "number") {
            objectMaxLength[j] = 5;
          } else {
            objectMaxLength[j] =
              objectMaxLength[j] >= value[j].length
                ? parseInt(objectMaxLength[j])
                : parseInt(value[j].length) + 5;
          }
        }
      }
      var wscols = [];
      objectMaxLength.forEach((value, index) => {
        console.log(value);
        wscols.push({ width: objectMaxLength[index] });
      }),
        (ws["!cols"] = wscols);

      var wb = window.XLSX.utils.book_new();
      window.XLSX.utils.book_append_sheet(wb, ws, name);
      window.XLSX.writeFile(wb, title + "." + (type || "xlsx"));
    },
    async getData(pages, keyword) {
      if (cancel !== undefined) cancel();
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      this.disable_date = true;
      this.returnData = [];
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", pages);
      bodyFormData.append("limit", this.perPage);
      bodyFormData.append("status", this.selectedStatus);
      bodyFormData.append("dateStart", this.date_range[0]);
      bodyFormData.append("dateEnd", this.date_range[1]);
      bodyFormData.append("searchKeyword",this.searchKeyword);
      console.log(
        keyword,
        pages,
        this.perPage,
        this.selectedStatus,
        this.selectedUsers.username,
        this.searchKeyword,
        this.date_range[0],
        this.date_range[1]
      );
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllBotUtrList',
        //url: appConfig.DemoAPI,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
        cancelToken: new CancelToken((c) => (cancel = c))
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData)
            this.returnData = [];
            this.returnData = resData.data;
            this.totalData = resData.total;
          }
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async exportData() {
      this.$Progress.start();
      this.disableExport = true;
      this.loadingButton = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "999999");
      bodyFormData.append("category", this.selectedStatus);
      bodyFormData.append("processBy", this.selectedUsers.username);
      bodyFormData.append("dateStart", this.date_range[0]);
      bodyFormData.append("dateEnd", this.date_range[1]);
      bodyFormData.append("keyword",this.searchKeyword);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/log/getAllLogList',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.export_data = [];
          var resData = response.data;
          this.totalData = resData.total;
          var exportData = resData.data;

          for (var i in exportData) {
            const listData = {
              id: `${exportData[i].id}`,
              created_date: `${exportData[i].created_at}`,
              categories: `${exportData[i].module}`,
              log_events: `${exportData[i].title}`,
              process_by: `${exportData[i].admin_username}`,
            };
            this.export_data.splice(i, 0, listData);
          }
          var self = this;
          setTimeout(function () {
            self.disableExport = false;
            self.loadingButton = false;
            self.exportToExcel(self.export_data, `Admin Logs`, "xlsx");
          }, 1200);

          // if (resData.status == 200) {
          //   console.log(resData)
          //   this.totalData = 10
          // }
          // else if (resData.status == 440){
          //   Swal.fire(
          //       {
          //       icon: 'error',
          //       title: 'Oops...',
          //       html: `${resData.message}.`,
          //       confirmButtonColor: '#222',
          //       confirmButtonText: this.$t('siteLang.Done'),
          //     })
          //     localStorage.clear();
          //     this.$router.push({
          //         path: "/login",
          //   });
          // }
          // else {
          //     Swal.fire(
          //       {
          //       icon: 'error',
          //       title: 'Oops...',
          //       html: `${resData.message}.`,
          //       confirmButtonColor: '#222',
          //       confirmButtonText: this.$t('siteLang.Done'),
          //     })
          // }
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.disableExport = false;
          this.loadingButton = false;
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.currentMerchantInfo = data.merchant_info;
          this.currentResellerInfo = data.reseller_info;
          this.account_type = data.account_type;
          const permission = data.modules.filter(e => e.name==='administrator')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
            console.log(this.create_modify_permission)
          } else {
            console.log('Module with name "administrator" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  },
};
</script>
<style scoped>
.text-truncate2{
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
